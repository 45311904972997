import dynamic from "next/dynamic";
import { Suspense, memo } from "react";

const NotificationModal = dynamic(() => import("@/components/Dialog/Notification"), { ssr: false });
const LoginOffCanvas = dynamic(() => import("@/components/OffCanvas/Login"), { ssr: false });
const VoiceSearchModal = dynamic(() => import("@/components/Dialog/VoiceSearch"), { ssr: false });
const SearchOffCanvas = dynamic(() => import("@/components/OffCanvas/Search"), { ssr: false });
const UserDetailsInputOffCanvas = dynamic(() => import("@/components/OffCanvas/UserDetailsInput"), { ssr: false });
const CustomToast = dynamic(() => import("./CustomToast"), { ssr: false });

const OffCanvas = () => {

  return (
    <Suspense>
      <LoginOffCanvas />
      <NotificationModal />
      <VoiceSearchModal />
      <SearchOffCanvas />
      <UserDetailsInputOffCanvas />
      <CustomToast />
    </Suspense>
  )
}

export default memo(OffCanvas);